export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 16,
    sort: 'Type',
  },
  pagination: {
    centralRange: 1,
  },
  product: {
    noImageSrc: '/assets/images/coming_soon_thumbnail.jpg',
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#search',
      template: 'MainContent',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '#search-area',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialogButtonTablet',
      type: 'SearchBoxDialogButton',
      location: '#mobileSearch',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'SearchBoxDialogButtonMobile',
      type: 'SearchBoxDialogButton',
      location: '#search-area2',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'Type': 'Product Type',
        'SKU': 'Part Number',
        'Name': 'Name',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'DateCreated:desc': 'Added: Newest to Oldest',
        'DateCreated': 'Added: Oldest to Newest',
        'relevance': 'Best Match',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
