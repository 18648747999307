import { SearchPage } from 'Components'
import TemplateMainContent from 'Stores/printpal/Templates/MainContent.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
SearchBoxDialogButton,
SearchHeader,
SearchResult,
SearchBoxDialog
} 
export const tplMap = {
  'MainContent': TemplateMainContent,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'SearchBoxDialog': TemplateSearchBoxDialog
};